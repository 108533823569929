<template>
  <div class="goods-content" @click="toGoodsDetail()" v-if="goodsData">
    <div class="goods-image">
      <img :src="goodsData.picture" alt="" />
    </div>
    <div class="goods-info">
      <div class="title">{{goodsData.goodsName}}</div>
      <div class="price-look">
        <div class="price">
          <span class="small-1">￥</span>
          <span class="big">{{goodsData.price.indexOf(".") >= 0 ? goodsData.price.substring(0,goodsData.price.indexOf(".")) : goodsData.price}}</span>
          <span class="small-2">{{goodsData.price.indexOf(".") >= 0 ? goodsData.price.substring(goodsData.price.indexOf(".")) : ''}}</span>
        </div>
        <div class="button">查看</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'GoodsCard',
  props:{
    goodsData: {
      type: Object
    }
  },
  methods: {
    toGoodsDetail() {
      
      this.$emit('to-goods-detail', this.goodsData.wxUrl);
    }
  }
  
}
</script>
<style lang="less" scoped>
  .goods-content {
    display: flex;
    height: 92px;
    border-radius: 7px;
    background: #FAFAFA;
    overflow: hidden;
    .goods-image{
      width: 92px;
      height: 92px;
      flex-shrink: 0;
      img{
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .goods-info{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      padding: 10px 12px;
      .title{
        min-height: 2em;
        font-size: 15px;
        font-weight: bold;
        color: #222;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-all;
      }
      .price-look{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .button{
          width: 68px;
          height: 24px;
          margin-top: 5px;
          border-radius: 30px;
          line-height: 24px;
          background: #F7321C;
          font-size: 12px;
          color: white;
          text-align: center;
        }
        .price{
          display: flex;
          align-items: baseline;
          height: 25px;
          margin-top: 5px;
          color: #F7321C;
          .small-1{
            font-size: 13px;
          }
          .big{
            font-size: 21px;
            font-weight: bold;
          }
          .small-2{
            font-size: 13px;
            font-weight: bold;
          }
        }
      }
    }
  }
</style>