<template>
  <div class="labels-content">
    <div v-for="item in labelData" :key="item.labelOrder" class="labels-item" @click="clickHandler(item.labelName)">
      # {{item.labelName}}
    </div>
  </div>
</template>
<script>
export default {
  props:{
    labelData:{
      type: Array,
      default: () => []
    }
  },
  methods:{
    clickHandler(labelName) {
      this.$emit('clickHandler', labelName)
    }
  }
}
</script>
<style lang="less" scoped>
.labels-content{
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  line-height: 15px;
  .labels-item{
    padding: 4px 10px 3px 10px;
    margin: 0 8px 8px 0;
    border-radius: 20px;
    background: #FAF3F3;
    color: #F7321C;
    font-weight: bold;
    font-size: 11px;
  }
}
</style>